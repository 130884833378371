
import { defineComponent, reactive } from 'vue'
import axios from '@/utils/axios'

import Swiper from './modules/swiper.vue'
import SubNav from './modules/subNav.vue'
import Box from './modules/box.vue'
import Sixbox from './modules/SixBoxa.vue'
import FourBoxa from './modules/FourBoxa.vue'
import Message from '@/components/message/Message.vue'
import { ref } from 'vue'
import * as echarts from 'echarts'
import '../../../node_modules/echarts/map/china.js' // 引入中国地图数据
export default defineComponent({
  name: 'home',
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      ambassadorImg: require('@/assets/1new/赋能工程/赋能大使.jpg'),
      glassImg: require('@/assets/1new/赋能工程/2.jpg'),
    }
  },
  methods: {
    search() {
      axios
        .get('https://www.xsy985.com/api/expert/searchexpert/')
        .then(function (response) {
          // console.log(response, "search");
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    //
    goDetail(a: any) {
      this.$router.push({ name: 'ambassadorDetail', params: { id: a } })
    },
  },
  setup() {
    var count = reactive({ data: '' })
    var mess = reactive({ data: '' })
    var list = reactive({ data: '' })
    var searchMess = ref()
    var searchMess2 = ref('全部')

    axios
      .get(
        'https://www.xsy985.com/api/expert/expert?page=1&page_size=&expert_type=',
      )
      .then(function (response) {
        console.log('11111111111112', response.data)
        mess.data = response.data.results
      })
      .catch(function (error) {
        console.log(error)
      })
    axios
      .get('https://www.xsy985.com/api/expert/province')
      .then(function (response) {
        console.log(response, 'province')
        response.data.unshift('全部')
        list.data = response.data
      })
      .catch(function (error) {
        console.log(error)
      })
    //  axios
    //   .get(
    //     `https://www.xsy985.com/api/expert/searchexpert?page=1&page_size=3&service_province=${val}`
    //   )
    //   .then(function (response) {
    //     console.log(response, "搜索结果");
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    const searchNews = (val: any) => {
      console.log(val, '搜索内容')
      if (val == 'undefined') {
        return
      }
      axios
        .get(
          `https://www.xsy985.com/api/expert/searchexpert?page=1&name=${val}`,
        )
        .then(function (response) {
          console.log(response, '搜索结果')
          mess.data = response.data.results
          if (response.data.count == 0) {
            mess.data = '暂无'
          }
          window.scrollTo(0, 0)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    // 搜索
    const searchNews2 = (val: any) => {
      // console.log(val, "搜索内容");
      if (val === '全部') {
        val = ''
      }
      axios
        .get(
          `https://www.xsy985.com/api/expert/searchexpert?page=1&service_province=${val}`,
        )
        .then(function (response) {
          console.log(response, '搜索结果')
          mess.data = response.data.results
          if (response.data.count == 0) {
            mess.data = '暂无'
          }
          window.scrollTo(0, 0)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    return {
      mess,
      count,
      searchMess,
      searchMess2,
      searchNews,
      searchNews2,
      list,
    }
  },
})
